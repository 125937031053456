
import $ from 'jquery';
import 'bootstrap/js/src/util';
import 'bootstrap/js/src/carousel';

import '../scss/main.scss';

document.addEventListener("DOMContentLoaded", function(event) {
  $('.js-header-slides').carousel({
    interval: 5000,
    pause: false,
    ride: true,
    keyboard: true,
    wrap: true
  });


  $('.js-logo').on('click', (e) => {
    e.preventDefault();
    const aboutSection = document.getElementById("about");
    aboutSection.scrollIntoView({behavior: "smooth"});
  });

  window.onscroll = function (e) {
    const logo = this.document.querySelector('.js-logo');

    if(this.window.scrollY > 900) {
      logo.classList.add('is-small');
    } else {
      logo.classList.remove('is-small');
    }
  };
});
